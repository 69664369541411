var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.disks
    ? _c(
        "div",
        { staticClass: "glances-disk-io-wrapper" },
        _vm._l(_vm.disks, function (disk) {
          return _c("div", { key: disk.name, staticClass: "disk-row" }, [
            _c("p", { staticClass: "disk-name" }, [_vm._v(_vm._s(disk.name))]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: disk.readC ? `Count: ${disk.readC}` : "",
                    expression: "disk.readC ? `Count: ${disk.readC}` : ''",
                  },
                ],
                staticClass: "io-data read",
              },
              [
                _c("span", { staticClass: "lbl" }, [
                  _vm._v(_vm._s(_vm.$t("widgets.glances.disk-io-read")) + ":"),
                ]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm._f("formatSize")(disk.readB))),
                ]),
                _c("span", { class: `direction ${disk.readD}` }, [
                  _vm._v(_vm._s(_vm._f("getArrow")(disk.readD))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: disk.writeC ? `Count: ${disk.writeC}` : "",
                    expression: "disk.writeC ? `Count: ${disk.writeC}` : ''",
                  },
                ],
                staticClass: "io-data write",
              },
              [
                _c("span", { staticClass: "lbl" }, [
                  _vm._v(_vm._s(_vm.$t("widgets.glances.disk-io-write")) + ":"),
                ]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm._f("formatSize")(disk.writeB))),
                ]),
                _c("span", { class: `direction ${disk.writeD}` }, [
                  _vm._v(_vm._s(_vm._f("getArrow")(disk.writeD))),
                ]),
              ]
            ),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }